.once-or-monthtly {
    /*--background: #66D37E;*/
    margin-bottom: 40px;
}

.input-item {
    margin: 20px;
}

.donate-button {
    margin: 16px;
}

.honor-checkbox {
    --size: 24px;
    --background-checked: #66D37E;
}

.honor-checkbox::part(container) {
    border-radius: 6px;
    border: 2px solid #66D37E;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        width: 0;
    }

    100% {
        opacity: 1;
        width: auto;
    }
}

.section {
    animation: fade-in 0.5s ease-in;
}