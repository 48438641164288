#donor-section {
    max-width: 600px;
    min-width: 560px;
    display: inline-block;
    margin: 10px;
}

.error-message {
    color: red;
    display: none;
}

.hide {
    display: none;
}