@media (min-width: 768px) {
    .agroforestry {
            padding-left: 360px;
            padding-right: 360px
        }
    .show-on-mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    .show-on-desktop {
            display: none;
        }
}