ion-thumbnail {
    --size: 120px;
    --border-radius: 2px;
    position: relative;
    margin: 0 auto;
}

.icon-greenish {
    filter: invert(100%) sepia(19%) saturate(4683%) hue-rotate(46deg) brightness(98%) contrast(87%);
}

.ion-icon-donate {
    font-size: 64px;
    color: #66D37E;
}