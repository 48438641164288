.greenor-title  {
    padding: 5px 10px;
    color: #66d37e;
}

.greenor-title a {
    color: #66d37e;
}

.greenor-title a:hover {
    cursor: pointer;
    text-decoration-line: none;
    color: green;
}

#title-logo{
    max-width: 360px;
}