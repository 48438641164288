@font-face {
    font-family: 'icomoon';
    src: url("./../fonts/icomoon.eot?srf3rx");
    src: url("./../fonts/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("./../fonts/icomoon.ttf?srf3rx") format("truetype"), url("./../fonts/icomoon.woff?srf3rx") format("woff"), url("./../fonts/icomoon.svg?srf3rx#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

/* =======================================================
*
* 	Template Style
*
* ======================================================= */
body {
    font-family: "Oxygen", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.7;
    color: gray;
    background: #e8e8e8;
}

#page {
    position: relative;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.offcanvas #page {
    overflow: hidden;
    position: absolute;
}

.offcanvas #page:after {
    -webkit-transition: 2s;
    -o-transition: 2s;
    transition: 2s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.7);
    content: "";
}

a {
    color: #66D37E;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

a:hover,
a:active,
a:focus {
    color: #66D37E;
    outline: none;
    text-decoration: none;
}

p {
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
figure {
    color: #000;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
}

::-webkit-selection {
    color: #fff;
    background: #66D37E;
}

::-moz-selection {
    color: #fff;
    background: #66D37E;
}

::selection {
    color: #fff;
    background: #66D37E;
}

.container-wrap {
    /*max-width: 1060px;*/
    margin: 0 auto;
    /*margin-bottom: 1em;*/
    background: #fff;
}

@media screen and (max-width: 768px) {
    .container-wrap {
        width: 100%;
    }
}

.fh5co-nav {
    margin: 0 0 0 0;
}

@media screen and (max-width: 768px) {
    .fh5co-nav {
        margin: 0;
    }
}

.fh5co-nav .top-menu {
    padding: 34px 30px;
}

@media screen and (max-width: 768px) {
    .fh5co-nav .top-menu {
        padding: 28px 1em;
    }
}

.fh5co-nav #fh5co-logo {
    font-size: 24px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
}

.fh5co-nav #fh5co-logo a span {
    color: #66D37E;
}

.fh5co-nav a {
    padding: 5px 10px;
    color: #66d37e;
}

@media screen and (max-width: 768px) {
    .fh5co-nav .menu-1 {
        display: none;
    }
}

.fh5co-nav ul {
    padding: 0;
    margin: 5px 0 0 0;
}

.fh5co-nav ul li {
    font-family: "Source Sans Pro", sans-serif;
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
}

.fh5co-nav ul li a {
    position: relative;
    font-size: 13px;
    padding: 30px 12px;
    color: rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.fh5co-nav ul li a:after {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    content: '';
    width: 58%;
    height: 2px;
    background: #66D37E;
    margin: 0 auto;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.fh5co-nav ul li a:hover {
    color: #000;
}

.fh5co-nav ul li a:hover:after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fh5co-nav ul li.has-dropdown {
    position: relative;
}

.fh5co-nav ul li.has-dropdown .dropdown {
    width: 140px;
    -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
    z-index: 1002;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 40px;
    left: 0;
    text-align: left;
    background: #000;
    padding: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.fh5co-nav ul li.has-dropdown .dropdown:before {
    bottom: 100%;
    left: 40px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #000;
    border-width: 8px;
    margin-left: -8px;
}

.fh5co-nav ul li.has-dropdown .dropdown li {
    display: block;
    margin-bottom: 7px;
}

.fh5co-nav ul li.has-dropdown .dropdown li:last-child {
    margin-bottom: 0;
}

.fh5co-nav ul li.has-dropdown .dropdown li a {
    padding: 2px 0;
    display: block;
    color: #999999;
    line-height: 1.2;
    text-transform: none;
    font-size: 13px;
    letter-spacing: 0;
}

.fh5co-nav ul li.has-dropdown .dropdown li a:hover {
    color: #fff;
}

.fh5co-nav ul li.has-dropdown:hover a,
.fh5co-nav ul li.has-dropdown:focus a {
    color: #000;
}

.fh5co-nav ul li.btn-cta a {
    padding: 30px 0px !important;
    color: #fff;
}

.fh5co-nav ul li.btn-cta a span {
    background: #66d37e;
    padding: 4px 10px;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    border-radius: 100px;
}

.fh5co-nav ul li.btn-cta a:hover span {
    -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}

.fh5co-nav ul li.active>a {
    color: #000 !important;
    position: relative;
}

.fh5co-nav ul li.active>a:after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

#fh5co-counter,
.fh5co-bg {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
}

.fh5co-video {
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .fh5co-video {
        height: 450px;
    }
}

.fh5co-video a {
    z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -45px;
    margin-left: -45px;
    width: 90px;
    height: 90px;
    display: table;
    text-align: center;
    background: #fff;
    -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
    -ms-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
    -o-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.fh5co-video a i {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    font-size: 40px;
}

.fh5co-video .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.fh5co-video:hover .overlay {
    background: rgba(0, 0, 0, 0.7);
}

.fh5co-video:hover a {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}

#fh5co-hero {
    min-height: 500px;
    background: #fff url(https://greenor.s3.us-east-2.amazonaws.com/img/loader.gif) no-repeat center center;
    width: 100%;
    float: left;
}

#fh5co-hero .btn {
    font-size: 24px;
}

#fh5co-hero .btn.btn-primary {
    padding: 14px 30px !important;
}

#fh5co-hero .flexslider {
    border: none;
    z-index: 1;
    margin-bottom: 0;
}

#fh5co-hero .flexslider .slides {
    position: relative;
    overflow: hidden;
}

#fh5co-hero .flexslider .slides li {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    min-height: 500px;
    position: relative;
}

#fh5co-hero .flexslider .flex-control-nav {
    bottom: 80px;
    z-index: 1000;
    right: 20px;
    float: right;
    width: auto;
}

#fh5co-hero .flexslider .flex-control-nav li {
    display: block;
    margin-bottom: 10px;
}

#fh5co-hero .flexslider .flex-control-nav li a {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: none;
    width: 12px;
    height: 12px;
    cursor: pointer;
}

#fh5co-hero .flexslider .flex-control-nav li a.flex-active {
    cursor: pointer;
    background: transparent;
    border: 2px solid #66D37E;
}

#fh5co-hero .flexslider .flex-direction-nav {
    display: none;
}

#fh5co-hero .flexslider .slider-text {
    display: table;
    opacity: 0;
    min-height: 500px;
    z-index: 9;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner {
    display: table-cell;
    vertical-align: middle;
    min-height: 700px;
    padding: 2em;
}

@media screen and (max-width: 768px) {
    #fh5co-hero .flexslider .slider-text>.slider-text-inner {
        text-align: center;
    }
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner h1,
#fh5co-hero .flexslider .slider-text>.slider-text-inner h2 {
    margin: 0;
    padding: 0;
    color: black;
    font-family: "Oxygen", sans-serif;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner h1 {
    margin-bottom: 20px;
    font-size: 50px;
    line-height: 1.3;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    #fh5co-hero .flexslider .slider-text>.slider-text-inner h1 {
        font-size: 28px;
    }
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner h2 {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 30px;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner h2 a {
    color: rgba(102, 211, 126, 0.8);
    border-bottom: 1px solid rgba(102, 211, 126, 0.7);
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner .heading-section {
    font-size: 50px;
}

@media screen and (max-width: 768px) {
    #fh5co-hero .flexslider .slider-text>.slider-text-inner .heading-section {
        font-size: 30px;
    }
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner s .fh5co-lead {
    font-size: 20px;
    color: #fff;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner s .fh5co-lead .icon-heart {
    color: #d9534f;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner .btn {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #fff;
    padding: 20px 30px !important;
    border: none;
}

#fh5co-hero .flexslider .slider-text>.slider-text-inner .btn.btn-learn {
    background: #fff;
    color: black;
}

@media screen and (max-width: 768px) {
    #fh5co-hero .flexslider .slider-text>.slider-text-inner .btn {
        width: 100%;
    }
}

.fh5co-light-grey {
    background: #fafafa;
}

#fh5co-about,
#fh5co-services,
#fh5co-contact,
#fh5co-work,
#fh5co-blog,
#fh5co-counter,
#fh5co-footer {
    padding: 6em 3em;
    clear: both;
}

@media screen and (max-width: 768px) {

    #fh5co-about,
    #fh5co-services,
    #fh5co-contact,
    #fh5co-work,
    #fh5co-blog,
    #fh5co-counter,
    #fh5co-footer {
        padding: 3em 1em;
    }
}

.services {
    position: relative;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.services h3 {
    font-size: 13px;
    font-family: "Source Sans Pro", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}

.services h3 a {
    color: #000;
}

.services .icon {
    display: table;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media screen and (max-width: 992px) {
    .services .icon {
        margin: 0 auto 30px auto;
    }
}

.services .icon i {
    display: table-cell;
    vertical-align: middle;
    font-size: 40px;
    line-height: 40px;
    color: #66d37e;
}

.work {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 270px;
    width: 100%;
    display: table;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 20px;
}

@media screen and (max-width: 768px) {
    .work {
        height: 400px;
    }
}

@media screen and (max-width: 480px) {
    .work {
        height: 270px;
    }
}

.work .desc {
    display: table-cell;
    vertical-align: middle;
    height: 270px;
    background: #fff;
    opacity: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.work .desc h3 {
    font-size: 14px;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
}

.work .desc span {
    display: block;
    color: #999999;
    font-size: 12px;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translate3d(0, 15px, 0);
    transform: translate3d(0, 15px, 0);
}

@media screen and (max-width: 768px) {
    .work .desc {
        opacity: 1;
        background: transparent !important;
    }

    .work .desc h3 {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .work .desc span {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.work:hover .desc {
    opacity: 1;
}

.work:hover .desc h3 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.work:hover .desc span {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fh5co-counters {
    padding: 3em 0;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
}

.fh5co-counters .counter-wrap {
    border: 1px solid red !important;
}

.fh5co-counters .fh5co-counter {
    font-size: 40px;
    display: block;
    color: #66d37e;
    width: 100%;
    font-weight: 400;
    margin-bottom: .3em;
    font-family: "Source Sans Pro", sans-serif;
}

.fh5co-counters .fh5co-counter-label {
    font-size: 12px;
    margin-bottom: 2em;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: gray;
}

.fh5co-blog {
    width: 100%;
    float: left;
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .fh5co-blog {
        width: 100%;
    }
}

.fh5co-blog .blog-bg {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 270px;
}

@media screen and (max-width: 768px) {
    .fh5co-blog .blog-bg {
        height: 300px;
    }
}

.fh5co-blog .blog-text {
    position: relative;
    width: 100%;
    padding: 30px;
    float: left;
    border: 1px solid rgba(0, 0, 0, 0.05);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-align: center;
}

.fh5co-blog .blog-text span {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.3);
}

.fh5co-blog .blog-text .stuff {
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding-top: 20px;
}

.fh5co-blog .blog-text .stuff li {
    list-style: none;
    display: inline-block;
    margin-left: 10px;
}

.fh5co-blog .blog-text .stuff li:first-child {
    margin-left: 0;
}

.fh5co-blog .blog-text .stuff li:last-child {
    float: right;
}

.fh5co-blog .blog-text .stuff li:last-child i {
    padding-right: 0;
    padding-left: 10px;
}

.fh5co-blog .blog-text .stuff li i {
    font-size: 14px;
    padding-right: 5px;
}

.fh5co-blog .blog-text h3 {
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Source Sans Pro", sans-serif;
}

.fh5co-blog .blog-text h3 a {
    color: #66d37e;
}

.blog-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    width: 100%;
    padding: 0 !important;
}

.blog-flex .featured-blog,
.blog-flex .blog-entry {
    width: 50%;
}

@media screen and (max-width: 768px) {

    .blog-flex .featured-blog,
    .blog-flex .blog-entry {
        width: 100%;
    }
}

.blog-flex .featured-blog {
    padding: 2em;
    width: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    .blog-flex .featured-blog {
        width: 100%;
    }
}

.blog-flex .featured-blog:before {
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 15px;
    right: 15px;
    content: '';
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.blog-flex .featured-blog .desc-t {
    display: table;
    min-height: 430px;
    padding: 2em;
    z-index: 1;
}

.blog-flex .featured-blog .desc-t .desc-tc {
    display: table-cell;
    vertical-align: middle;
    height: inherit;
    z-index: 1;
}

.blog-flex .featured-blog .desc-t .featured-head {
    display: block;
    margin-bottom: 30px;
    font-size: 18px !important;
    color: rgba(255, 255, 255, 0.8);
}

.blog-flex .featured-blog .desc-t h3 {
    font-weight: 700;
    font-family: "Oxygen", sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 40px;
    margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .blog-flex .featured-blog .desc-t h3 {
        font-size: 24px;
    }
}

.blog-flex .featured-blog .desc-t h3 a {
    color: #fff;
}

.blog-flex .featured-blog .desc-t p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.8);
}

.blog-flex .featured-blog .desc-t .read-button {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
    color: white;
    border: 1px solid red;
    padding: 10px 15px;
    border: 1px solid white;
    color: #fff;
}

.blog-flex .blog-entry {
    padding: 4em;
}

@media screen and (max-width: 768px) {
    .blog-flex .blog-entry {
        padding: 4em 1em;
    }
}

.blog-flex .blog-entry h2 {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    margin-bottom: 40px;
    color: rgba(0, 0, 0, 0.4);
}

.blog-flex .blog-entry .blog-post {
    margin-bottom: 2.5em;
    display: block;
    position: relative;
}

.blog-flex .blog-entry .blog-post .desc {
    padding-left: 110px;
}

.blog-flex .blog-entry .blog-post .desc h3 {
    font-weight: 300;
    font-family: "Oxygen", sans-serif;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 10px;
}

.blog-flex .blog-entry .blog-post .desc span {
    font-size: 13px;
    color: #b3b3b3;
}

.blog-flex .blog-entry .blog-post .img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 90px;
    height: 90px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border: 4px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

.contact-info {
    margin-bottom: 4em;
    padding: 0;
}

.contact-info li {
    list-style: none;
    margin: 0 0 20px 0;
    position: relative;
    padding-left: 40px;
    color: #000;
}

.contact-info li i {
    position: absolute;
    top: .3em;
    left: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
}

.contact-info li a {
    color: #000;
}

.form-control {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border: none;
    font-size: 13px !important;
    font-weight: 300;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
}

.form-control:focus,
.form-control:active {
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.8);
}

input[type="text"] {
    height: 50px;
}

.form-group {
    margin-bottom: 30px;
}

.form-group .btn-modify {
    font-family: "Source Sans Pro", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 13px;
    padding: 10px 15px;
}

.fh5co-social-icons {
    margin: 0;
    padding: 0;
}

.fh5co-social-icons li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.fh5co-social-icons li a {
    display: -moz-inline-stack;
    display: inline-block;
    zoom: 1;
    *display: inline;
    color: #66D37E;
    padding-left: 10px;
    padding-right: 10px;
}

.fh5co-social-icons li a i {
    font-size: 20px;
}

.fh5co-heading {
    margin-bottom: 5em;
}

.fh5co-heading.fh5co-heading-sm {
    margin-bottom: 2em;
}

.fh5co-heading h2 {
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: 700;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000;
}

.fh5co-heading span {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
}

#fh5co-footer {
    margin-top: 20px !important;
}

#fh5co-footer .fh5co-footer-links {
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 768px) {
    #fh5co-footer .fh5co-footer-links {
        margin-bottom: 30px;
    }
}

#fh5co-footer .fh5co-footer-links li {
    padding: 0;
    margin: 0 0 10px 0;
    list-style: none;
    display: block;
}

#fh5co-footer .fh5co-footer-links li a {
    color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
}

#fh5co-footer .fh5co-footer-links li a:hover {
    text-decoration: underline;
}

#fh5co-footer h4 {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

#fh5co-footer .copyright {
    display: block;
    margin-top: 3em;
}

#fh5co-footer .copyright .block {
    display: block;
}

#fh5co-offcanvas {
    position: absolute;
    z-index: 1901;
    width: 270px;
    background: black;
    top: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 75px 40px 40px 40px;
    overflow-y: auto;
    display: none;
    -moz-transform: translateX(270px);
    -webkit-transform: translateX(270px);
    -ms-transform: translateX(270px);
    -o-transform: translateX(270px);
    transform: translateX(270px);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

@media screen and (max-width: 768px) {
    #fh5co-offcanvas {
        display: block;
    }
}

.offcanvas #fh5co-offcanvas {
    -moz-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
}

#fh5co-offcanvas a {
    color: rgba(255, 255, 255, 0.5);
}

#fh5co-offcanvas a:hover {
    color: rgba(255, 255, 255, 0.8);
}

#fh5co-offcanvas ul {
    padding: 0;
    margin: 0;
}

#fh5co-offcanvas ul li {
    padding: 0;
    margin: 0;
    list-style: none;
}

#fh5co-offcanvas ul li>ul {
    padding-left: 20px;
    display: none;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown>a {
    display: block;
    position: relative;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown>a:after {
    position: absolute;
    right: 0px;
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e0c5";
    font-size: 20px;
    color: rgba(255, 255, 255, 0.2);
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

#fh5co-offcanvas ul li.offcanvas-has-dropdown.active a:after {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.gototop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.gototop.active {
    opacity: 1;
    visibility: visible;
}

.gototop a {
    width: 50px;
    height: 50px;
    display: table;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.gototop a i {
    height: 50px;
    display: table-cell;
    vertical-align: middle;
}

.gototop a:hover,
.gototop a:active,
.gototop a:focus {
    text-decoration: none;
    outline: none;
}
/* Comment out the template hamburger menu
.fh5co-nav-toggle {
    width: 25px;
    height: 25px;
    cursor: pointer;
    text-decoration: none;
    top: 25px !important;
}

.fh5co-nav-toggle.active i::before,
.fh5co-nav-toggle.active i::after {
    background: #444;
}

.fh5co-nav-toggle:hover,
.fh5co-nav-toggle:focus,
.fh5co-nav-toggle:active {
    outline: none;
    border-bottom: none !important;
}

.fh5co-nav-toggle i {
    position: relative;
    display: inline-block;
    width: 25px;
    height: 2px;
    color: #252525;
    font: bold 14px/.4 Helvetica;
    text-transform: uppercase;
    text-indent: -55px;
    background: #252525;
    transition: all .2s ease-out;
}

.fh5co-nav-toggle i::before,
.fh5co-nav-toggle i::after {
    content: '';
    width: 25px;
    height: 2px;
    background: #252525;
    position: absolute;
    left: 0;
    transition: all .2s ease-out;
}

.fh5co-nav-toggle.fh5co-nav-white>i {
    color: #fff;
    background: #000;
}

.fh5co-nav-toggle.fh5co-nav-white>i::before,
.fh5co-nav-toggle.fh5co-nav-white>i::after {
    background: #000;
}

.fh5co-nav-toggle i::before {
    top: -7px;
}

.fh5co-nav-toggle i::after {
    bottom: -7px;
}

.fh5co-nav-toggle:hover i::before {
    top: -10px;
}

.fh5co-nav-toggle:hover i::after {
    bottom: -10px;
}

.fh5co-nav-toggle.active i {
    background: transparent;
}

.fh5co-nav-toggle.active i::before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
    background: #fff;
}

.fh5co-nav-toggle.active i::after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
    background: #fff;
}

.fh5co-nav-toggle {
    position: absolute;
    right: 0px;
    top: 65px;
    z-index: 21;
    padding: 6px 0 0 0;
    display: block;
    margin: 0 auto;
    display: none;
    height: 44px;
    width: 44px;
    z-index: 2001;
    border-bottom: none !important;
}

@media screen and (max-width: 768px) {
    .fh5co-nav-toggle {
        display: block;
    }
}*/

.btn {
    margin-right: 4px;
    margin-bottom: 4px;
    font-family: "Oxygen", sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    border-radius: 1px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding: 8px 20px;
}

.btn.btn-md {
    padding: 8px 20px !important;
}

.btn.btn-lg {
    padding: 18px 36px !important;
}

.btn:hover,
.btn:active,
.btn:focus {
    box-shadow: none !important;
    outline: none !important;
}

.btn-primary {
    background: #66D37E !important;
    color: #fff;
    border: 2px solid #66D37E;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: #7ad98f !important;
    border-color: #7ad98f !important;
}

.btn-primary.btn-outline {
    background: transparent;
    color: #66D37E;
    border: 2px solid #66D37E;
}

.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline:active {
    background: #66D37E;
    color: #fff;
}

.btn-success {
    background: #5cb85c;
    color: #fff;
    border: 2px solid #5cb85c;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active {
    background: #4cae4c !important;
    border-color: #4cae4c !important;
}

.btn-success.btn-outline {
    background: transparent;
    color: #5cb85c;
    border: 2px solid #5cb85c;
}

.btn-success.btn-outline:hover,
.btn-success.btn-outline:focus,
.btn-success.btn-outline:active {
    background: #5cb85c;
    color: #fff;
}

.btn-info {
    background: #5bc0de;
    color: #fff;
    border: 2px solid #5bc0de;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active {
    background: #46b8da !important;
    border-color: #46b8da !important;
}

.btn-info.btn-outline {
    background: transparent;
    color: #5bc0de;
    border: 2px solid #5bc0de;
}

.btn-info.btn-outline:hover,
.btn-info.btn-outline:focus,
.btn-info.btn-outline:active {
    background: #5bc0de;
    color: #fff;
}

.btn-warning {
    background: #f0ad4e;
    color: #fff;
    border: 2px solid #f0ad4e;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active {
    background: #eea236 !important;
    border-color: #eea236 !important;
}

.btn-warning.btn-outline {
    background: transparent;
    color: #f0ad4e;
    border: 2px solid #f0ad4e;
}

.btn-warning.btn-outline:hover,
.btn-warning.btn-outline:focus,
.btn-warning.btn-outline:active {
    background: #f0ad4e;
    color: #fff;
}

.btn-danger {
    background: #d9534f;
    color: #fff;
    border: 2px solid #d9534f;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
    background: #d43f3a !important;
    border-color: #d43f3a !important;
}

.btn-danger.btn-outline {
    background: transparent;
    color: #d9534f;
    border: 2px solid #d9534f;
}

.btn-danger.btn-outline:hover,
.btn-danger.btn-outline:focus,
.btn-danger.btn-outline:active {
    background: #d9534f;
    color: #fff;
}

.btn-outline {
    background: none;
    border: 2px solid gray;
    font-size: 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
    box-shadow: none;
}

.btn.with-arrow {
    position: relative;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.btn.with-arrow i {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -8px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.btn.with-arrow:hover {
    padding-right: 50px;
}

.btn.with-arrow:hover i {
    color: #fff;
    right: 18px;
    visibility: visible;
    opacity: 1;
}

.form-control {
    box-shadow: none;
    background: transparent;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 54px;
    font-size: 18px;
    font-weight: 300;
}

.form-control:active,
.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #66D37E;
}

.row-pb-md {
    padding-bottom: 4em !important;
}

.row-pb-sm {
    padding-bottom: 2em !important;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.fh5co-loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(https://greenor.s3.us-east-2.amazonaws.com/img/loader.gif) center no-repeat #fff;
}

.js .animate-box {
    opacity: 0;
}

.img-portfolio-detail {
    margin-bottom: 20px;
    float: left;
}

.fh5co-project-detail .fh5co-project-title {
    font-size: 20px;
    margin-bottom: 5px;
}

.fh5co-project-detail .fh5co-project-sub {
    color: rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    display: block;
    font-weight: 400;
}

.fh5co-project-detail .fh5co-project-service h3 {
    font-size: 18px;
    margin-bottom: 5px;
}

.fh5co-project-detail .fh5co-project-service ul li,
.fh5co-project-detail .fh5co-project-service ul {
    padding: 0;
    margin: 0;
}

.fh5co-project-detail .fh5co-project-service ul li {
    color: ligthen(#000, 70%);
    list-style: none;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
}

/*# sourceMappingURL=style.css.map */

.white-text {
    color: white !important;
}

.greenish-background {
    background: linear-gradient(0deg, rgba(102, 211, 146, 0.2) 0%, rgba(232, 250, 218, 0) 100%);
}

.green-color {
    color: #66d37e;
}

#lone-tree {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/lone_tree.webp);
}
#plant-seedling {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/plant_seedling_in_hand.webp);
}
#impact-home {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/mountain_mine.webp);
}
#agro-home-top {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry.webp);
}
#agro-home {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/agroforestry_farmer.webp);
}
#carbon-in-air {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/carbon_in_air.webp);
}
#recycle-reuse {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/electronics_recycle_2.jpeg);
}
#in-together {
    background-image: url(https://greenor.s3.us-east-2.amazonaws.com/img/in-together.webp);
}