ion-segment-button {
    --border-radius: 5px;
    /*--border-style: groove;
    background: whitesmoke;*/
    margin: 3px;
    border: 1px solid #d2d2d2;
}

.trees {
    color: green;
    font-size: larger;
    font-weight: bolder;
}

ion-toast.custom-toast {
    --background: #F4F4FA;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
}

ion-toast.custom-toast::part(message) {
    font-style: italic;
}

ion-toast.custom-toast::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
}

.section {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px 3px whitesmoke;
}